<template>
  <div v-if="checkPermission(['category', 'PUT'])">
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div class="centerx">
      <vs-popup
        :id="'popmodal' + params.data.category_id"
        class="holamundo editModal"
        title="Update Category"
        :active.sync="popupActive"
      >
        <form>
          <FocusLock>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div
                    class="col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Category Name*</h5>
                  </div>
                  <div class="col-md-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="category name"
                        v-model="form.category_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('category name')"
                        >{{ errors.first("category name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-left md:text-right">
                    <h5 class="mb-0">Description</h5>
                  </div>
                  <div class="col-md-7">
                    <vs-textarea
                      v-model="form.category_description"
                      class="mb-0"
                      rows="4"
                    />
                  </div>
                </div>
                <!-- <div class="vx-row mb-6">
                <div class="col-md-4 text-right">
                  <h5 class="mb-0">Description</h5>
                </div>
                <div class="col-md-7">
                  <vs-textarea
                    v-model="form.description"
                    class="mb-0"
                    rows="4"
                  />
                </div>
                </div>-->
                <!-- <div class="vx-row mb-6">
                <div class="col-md-4 text-right">
                  <h5 class="mb-0">Status*</h5>
                </div>
                <div class="col-md-7">
                  <b-form-checkbox
                    value="1"
                    unchecked-value="2"
                    v-model="form.category_active"
                    switch
                  ></b-form-checkbox>
                </div>
                </div>-->
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <!-- :disabled="submitStatus" -->
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm()"
                  class="mr-3"
                  >Save</vs-button
                >

                <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm()">Reset</vs-button> -->
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import categoryService from "@/services/categoryService.js";
import CompanyService from "@/services/companyService.js";
export default Vue.extend({
  data() {
    return {
      switch1: true,
      submitStatus: true,
      form: {
        // company_id: null,
        // organization_id: null,
        // organization_name: "",
        category_name: "",
        category_description: "",
        category_active: 1,
      },
      companies: [],
      popupActive: false,
      params: null,
      value: null,
      count: 0,
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        if (this.count > 0) {
          this.submitStatus = false;
        }
        this.count++;
      },
      deep: true,
    },
  },
  beforeMount() {
    this.value = this.params.value;
    this.form = this.params.data;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodal" + this.params.data.category_id
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    showModal() {
      // console.log(this.params.data.department_active, "fsdfsd");

      // console.log("afsadf");
      this.popupActive = true;
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          let id = this.params.data.category_id;
          let payload = {
            // category_name: this.form.category_name,
            // category_active: this.form.category_active,
            // organization_id: this.form.organization_id,
            // organization_name: this.form.organization_name,
            project_id: this.params.data.project_id,
            category_name: this.form.category_name,
            category_description: this.form.category_description,
            category_active: this.form.category_active,
          };
          categoryService
            .editCategory(payload, id)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              this.popupActive = false;

              if (data.Status) {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.data.message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                setTimeout(() => {
                  eventBus.$emit("refreshTablecat", data);
                }, 1);
                this.clearForm();
              } else {
                this.$vs.loading.close();
                console.log("data :", data);
                this.$vs.notify({
                  title: "Something went wrong",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
              console.log("error :", error);
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },
    getCompanyList: function() {
      // console.log("RolesService :", RolesService);
      CompanyService.getAllCompanies()
        .then((response) => {
          const { data } = response;
          if (data.err) {
          } else {
            this.companies = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
  },

  // mounted() {
  //   this.getCompanyList();
  // },
});

// import Vue from "vue";

// export default Vue.extend({
//   template: ` {{params.value}}
//          <label class="switch">
//           <input @click="onChangeCheckbox" type="checkbox">
//           <span class="slider round"></span>
//     </label> `,
//   data: function () {
//     return {};
//   },
//   beforeMount() {},
//   mounted() {},
//   methods: {

//   }
// });
</script>
<style></style>
