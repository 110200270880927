<!-- =========================================================================================
   File Name: categoryView.vue
    Description: Category
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:  
    Author URL:   
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/apps/projects">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>
      <!-- CATEGORY FORMS -->
      <div class="vx-col w-full mb-base">
        <category-form v-if="checkPermission(['category','POST'])"></category-form>
      </div>
      <!-- <div class="vx-col w-full">
        <vx-card class="form-title mb-base" title="Filter Category List">
          <form>
            <div class="row mb-6">
              <div class="col-3 text-right">
                <h6 class="mb-0">Project</h6>
              </div>
              <div class="col-6">
                <multiselect
                  v-model="projects_value"
                  track-by="project_id"
                  label="project_name"
                  :options="projects"
                  name="Project Name"
                  placeholder="Select Project"
                  :searchable="true"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{ option.project_name }}</template>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                  >{{ errors.first('Project Name') }}</span>
                </multiselect>
              </div>
            </div>
            <div class="vx-col mx-auto text-center">
              <vs-button type="filled" class="mr-3" @click.prevent="filterCategoryList">Submit</vs-button>
            </div>
          </form>
        </vx-card>
      </div>-->
      <!-- CATEGORY LIST -->
      <div class="vx-col w-full">
        <vs-tabs v-if="checkPermission(['category','GET'])">
          <vs-tab label="Category List" icon-pack="feather" icon="icon-box">
            <div class="tab-text">
              <!-- CATEGORY LIST -->
              <div>
                <category-list></category-list>
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Archive List" icon-pack="feather" icon="icon-box">
            <!-- CATEGORY ARCHIVE LIST -->
            <div>
              <category-list listType="archive"></category-list>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import categoryForm from "@/views/apps/category/categoryForm.vue";
import categoryList from "@/views/apps/category/categoryList.vue";
import projectService from "@/services/projectService.js";

export default {
  data() {
    return {
      isActive: false,
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      projects: [],
      projects_value: [],
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot",
      ],
    };
  },
  mounted() {
    this.getProjectsList();
    if (this.$store.getters.getProjectID) {
      setTimeout(
        () =>
          eventBus.$emit(
            "refreshTableCategoryList",
            this.$store.getters.getProjectID
          ),
        1
      );
    }
  },
  methods: {
    myFilter: function () {
      this.isActive = !this.isActive;
    },
    getProjectsList: function () {
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.projects = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterCategoryList: function () {
      let id = this.projects_value.project_id;
      this.$store.dispatch("SetProjectID", id);
      setTimeout(() => {
        eventBus.$emit("refreshTableCategoryList", id);
      }, 1);
    },
  },
  components: {
    categoryForm,
    categoryList,
  },
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>
