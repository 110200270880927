<template>
  <div>
    <span class="text-center table-edit-icon" @click="popupActive = true">
      <i
        v-if="params.data.category_active == 4"
        class="text-center material-icons"
        >unarchive</i
      >
      <i v-else class="text-center material-icons">archive</i>
    </span>
    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalA' + params.data.category_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Category Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5 v-if="params.data.category_active == 4">
            Do you want to unarchive?
          </h5>
          <h5 v-else>Do you want to archive?</h5>

          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
                >Yes</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import categoryService from "@/services/categoryService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        category_active: "4",
      },
      copy_category_active: null,
    };
  },
  beforeMount() {},
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalA" + this.params.data.category_id
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function (e, value) {
      console.log(`this.popupActive`, this.popupActive);
      this.popupActive = false;

      console.log("this.params", this.params);
      this.$vs.loading();

      let id = this.params.data.category_id;
      let payload = {
        category_active: this.params.data.category_active == "4" ? "1" : "4",
      };
      categoryService
        .editCategory(payload, id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.$vs.notify({
              title: "Updated!",
              text: data.data.message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            setTimeout(() => {
              eventBus.$emit("refreshTablecat", data);
            }, 1);
          } else {
            this.$vs.loading.close();
            console.log("data :", data);
            this.$vs.notify({
              title: "Something went wrong",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    privStatus: function () {
      this.popupActive = false;
      this.form.category_active = this.copy_category_active;
    },
  },
});
</script>