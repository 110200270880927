<!-- =========================================================================================
    File Name: categoryForm.vue
    Description: Category
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->

<template>
  <div>
    <!-- ADD Category FORM  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <!-- ******************* -->

        <!--BUDGET IMPORT FROM EXCEL POPUP -->

        <vs-popup
          :button-close-hidden="false"
          class="holamundo p-2 assigneePopup"
          title="Import Category from Excel"
          :active.sync="popupActiveCategory"
        >
          <div class="text-center position-relative">
            <form>
              <div class="row">
                <div class="col-8">
                  <!-- Start ************* -->
                  <div>
                    <multiselect
                      v-model="projects_value_new"
                      track-by="project_id"
                      label="project_name"
                      :options="projects"
                      name="Project Name"
                      placeholder="Select Project"
                      :searchable="true"
                      :allow-empty="false"
                      open-direction="bottom"
                      deselect-label="Can't remove this value"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.project_name }}
                      </template>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Project Name')"
                        >{{ errors.first("Project Name") }}</span
                      >
                    </multiselect>
                    <p class="error-msg">
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Project Name')"
                        >{{ errors.first("Project Name") }}</span
                      >
                    </p>
                  </div>
                  <!-- ************* End -->
                </div>

                <div class="col-4">
                  <vx-tooltip text="Import Excel" position="left">
                    <label for="import-file">
                      <input
                        :disabled="projects_value_new == null ? true : false"
                        ref="uploadExcelFile"
                        @change="uploadExcel($event)"
                        type="file"
                        name="import-file"
                        id="import-file"
                        class="d-none"
                      />
                      <!-- class="d-none" -->
                      <div
                        class="vs-component vs-button px-4 vs-button-primary vs-button-filled"
                        :style="
                          projects_value_new == null
                            ? 'opacity: .5; cursor: default;pointer-events: none;'
                            : ''
                        "
                      >
                        <span class="d-inline-flex pr-5 text-white"
                          >Import</span
                        >
                        <img
                          class="d-inline-flex"
                          src="@/assets/images/icons/import-excel.svg"
                          height="20"
                          width="20"
                        />
                      </div>
                    </label>
                  </vx-tooltip>
                </div>
              </div>

              <!-- SUBMIT BUTTON -->
              <div class="vx-row">
                <div class="vx-col mx-auto">
                  <!-- :disabled="submitStatus"
                  @click.prevent="submitForm"-->
                  <vs-button
                    type="filled"
                    class="mr-3"
                    :disabled="projects_value_new == null ? true : false"
                    @click.prevent="submitExcel"
                    >Submit</vs-button
                  >
                  <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
                </div>
              </div>
            </form>
          </div>
        </vs-popup>

        <!-- ******************* -->

        <vx-card class="form-title" title="Add Category">
          <div class="vx-row col-12 d-flex justify-content-end">
            <div class="vx-col mb-3">
              <vs-button
                @click.prevent="showActiveCategory"
                class="px-2 py-3"
                color="primary"
                type="border"
              >
                <img
                  class="d-inline-flex mr-2"
                  src="@/assets/images/icons/excel.svg"
                  height="20"
                  width="20"
                  alt
                />Import from Excel
              </vs-button>
            </div>
            <div class="vx-col">
              <div class="">
                <vx-tooltip text="Template Excel" position="left">
                  <vs-button
                    type="border"
                    class="px-2 py-3"
                    @click.native="
                      onClickSampleDownload(
                        excelurl + `/v2/category/excel/sample`
                      )
                    "
                  >
                    <img
                      class="d-inline-flex mr-2"
                      src="@/assets/images/icons/excel.svg"
                      height="20"
                      width="20"
                    />Sample Excel
                  </vs-button>
                </vx-tooltip>
              </div>

              <!-- <div class="excel-btn-2">
                  <vs-button
                    @click.prevent="showActiveCategory"
                    class="px-2 py-3"
                    color="primary"
                    type="border"
                  >
                    <img
                      class="d-inline-flex mr-2"
                      src="@/assets/images/icons/excel.svg"
                      height="20"
                      width="20"
                      alt
                    />Import from Excel
                  </vs-button>
                </div> -->
            </div>
          </div>
          <form>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="vx-col md:w-3/4 mx-auto">
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full">
                    <h6 class="mb-0 mb-2">Project</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                      v-model="projects_value"
                      track-by="project_id"
                      label="project_name"
                      :options="projects"
                      name="Project Name"
                      placeholder="Select Project"
                      :searchable="true"
                      @select="categoryList"
                      :allow-empty="false"
                      open-direction="bottom"
                      deselect-label="Can't remove this value"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.project_name }}
                      </template>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Project Name')"
                        >{{ errors.first("Project Name") }}</span
                      >
                    </multiselect>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full align-self-center">
                    <h6 class="mb-0 mb-2">Category Name*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="Category Name"
                        v-model="form.category_name"
                        class="w-full"
                        rules="alpha"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Category Name')"
                        >{{ errors.first("Category Name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full">
                    <h6 class="md-mb-0 mb-2">All Categories</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                      v-model="category_value"
                      track-by="category_id"
                      :custom-label="nameWithParent"
                      label="category_name"
                      @input="
                        ({ category_id }) => (this.category_id = category_id)
                      "
                      @remove="onRemoveItem"
                      :options="categoriesByProject"
                      name="Category"
                      :searchable="true"
                      :allow-empty="true"
                      :clear-on-select="false"
                      open-direction="bottom"
                      :preselect-first="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.category_name }}
                        <span
                          class="text-primary"
                          v-show="option.parent_category_name != null"
                          >{{ " [" + option.parent_category_name + "]" }}</span
                        >
                      </template>
                    </multiselect>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full ">
                    <h6 class="mb-0 mb-2">Description</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-textarea
                      v-model="category_description"
                      class="mb-0"
                      rows="4"
                    />
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full ">
                    <h6 class="mb-0 mb-2">Status</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <!-- <vs-switch v-model="switch1" /> -->
                    <div class="vx-col sm:w-2/3 w-full">
                      <!-- <vs-switch false-value="2" true-value="1" v-model="form.category_active" /> -->
                      <b-form-checkbox
                        value="1"
                        unchecked-value="2"
                        v-model="form.category_active"
                        name="check-button"
                        switch
                      ></b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row d-flex justify-content-center">
              <div class="vx-col">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3 mb-2"
                  block
                  >Submit</vs-button
                >
              </div>
              <div class="vx-col">
                <vs-button
                  color="warning"
                  type="border"
                  class="mb-2 mr-3"
                  block
                  @click.prevent="clearForm"
                  >Reset</vs-button
                >
              </div>
              <!-- <div class="vx-col">
                <div class="excel-btn-1">
                  <vx-tooltip text="Template Excel" position="left">
                    <vs-button
                      type="border"
                      class="py-2"
                      @click.native="
                        onClickSampleDownload(
                          excelurl + `/v2/category/excel/sample`
                        )
                      "
                    >
                      <img
                        class="d-inline-flex"
                        src="@/assets/images/icons/excel.svg"
                        height="20"
                        width="20"
                      />
                    </vs-button>
                  </vx-tooltip>
                </div>

                // <div class="excel-btn-2">
                  <vs-button
                    @click.prevent="showActiveCategory"
                    class="px-2 py-3"
                    color="primary"
                    type="border"
                  >
                    <img
                      class="d-inline-flex mr-2"
                      src="@/assets/images/icons/excel.svg"
                      height="20"
                      width="20"
                      alt
                    />Import from Excel
                  </vs-button>
                </div> //
              </div> -->
            </div>
          </form>
        </vx-card>
      </div>
    </div>
    <!-- <vs-popup
      class="holamundo"
      title="Lorem ipsum dolor sit amet"
      :active.sync="categoryImportPopup"
    >
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
    </vs-popup>-->
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import CategoryService from "@/services/categoryService.js";
import projectService from "@/services/projectService.js";
import { BASEURL } from "@/config/index.js";
import axios from "axios";
const VxTour = () => import("@/components/VxTour.vue");
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
  },
  data() {
    return {
      category_value: [],
      excelurl: BASEURL,
      popupActiveCategory: false,
      switch1: true,
      submitStatuscat: true,
      submitStatus: true,
      category_description: "",
      categoryImportPopup: false,
      form: {
        category_name: "",
        category_active: "1",
      },
      category_id: "",
      User: "Select User",
      searchQuery: "",
      categories: [],
      projects: [],
      projects_value: [],
      projects_value_new: null,
      project_id: "",
      categoriesByProject: [],
      gridOptions: {},
      excelUpload: null,
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: true,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
      organization_id: "",
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    projects_value_new: function(newVal, oldVal) {
      console.log("newVal :", newVal);
      if (Object.keys(newVal).length > 0) {
        this.submitStatuscat = false;
      } else {
        this.submitStatuscat = true;
      }
    },
    form: {
      handler: function(newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
    kilometers: function(val) {
      this.kilometers = val;
      this.meters = val * 1000;
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    showActiveCategory: function() {
      this.popupActiveCategory = true;
    },
    nameWithParent({ category_name, parent_category_name }) {
      if (parent_category_name == null) {
        return `${category_name}`;
      } else {
        return `${category_name} — [${parent_category_name}]`;
      }
    },
    onClickSampleDownload: function(URL) {
      window.open(URL, "_blank");
    },
    onRemoveItem: function(removedOption, id) {
      console.log("this :", removedOption, id);
      // this.category_id = "0";
    },

    submitExcel: function() {
      let that = this;
      that.$vs.loading();
      let formData = new FormData();
      formData.append("file", this.excelUpload);
      formData.append("project_id", this.projects_value_new.project_id);
      axios
        .post(
          that.excelurl +
            `/v2/category/import/excel?organization_id=${that.organization_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const { data } = response;
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";
          if (
            data["data"] &&
            data.data["message"] &&
            data.statusCode == "200"
          ) {
            that.$vs.notify({
              title: "Updated!",
              text: data.data.message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
          } else {
            that.$vs.notify({
              title: "Error!",
              text: data.error.message,
              iconPack: "feather",
              time: 35000,
              icon: "check_box",
              color: "warning",
            });
          }
          that.$vs.loading.close();
          eventBus.$emit("refreshTablecat", data);
        })
        .catch((error) => {
          that.$vs.loading.close();
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";
          that.$vs.notify({
            // title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
          this.categoryImportPopup = true;
        });
    },
    uploadExcel: function(event) {
      this.excelUpload = event.target.files[0];
      this.$vs.notify({
        title: "Your file is imported",
        text: "Kindly click on the SUBMIT button to upload data succesfully",
        time: 5000,
        iconPack: "feather",
        icon: "check_box",
        color: "warning",
      });
    },
    getProjectsList: function() {
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.projects = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    categoryList: function(selectedOption, id) {
      this.project_id = selectedOption.project_id;
      this.getAllCategoryListByProject(this.project_id);
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submitStatus = false;
          this.$vs.loading();

          let payload = {
            category_name: this.form.category_name,
            parent_id: this.category_id,
            category_description: this.category_description,
            category_active: this.form.category_active,
            project_id: this.projects_value.project_id,
          };
          CategoryService.addCategory(payload)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              if (data.Status) {
                this.$vs.notify({
                  title: "Added Successfully",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                setTimeout(() => {
                  eventBus.$emit("refreshTablecat", data);
                }, 1);
                // this.categoryList(selectedOption, id);
                this.getAllCategoryListByProject(this.project_id);
                this.clearForm();
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error!",
                text: error.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },

    getAllCategoryListByProject: function(id) {
      CategoryService.getProjectMainCategories(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoriesByProject = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    // getCategoryList: function() {
    //   CategoryService.getAllCategories()
    //     .then(response => {
    //       const { data } = response;
    //       if (data.Status == true || data.Status == "true") {
    //         this.categories = data.data.filter(
    //           x => String(x.parent_id) === "0"
    //         );
    //       }
    //     })
    //     .catch(error => {
    //       console.log("error:", error);
    //     });
    // },
    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
      this.category_value = [];
      this.category_description = "";
      this.projects_value = [];
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    this.getProjectsList();
    this.gridApi = this.gridOptions.api;
    this.organization_id = localStorage.getItem("OrganizationID");
    // if (this.checkPermission(["category", "GET"])) {
    //   this.getCategoryList(this.currentPage);
    // }
  },
};
</script>
